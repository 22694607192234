define('ember-href-to/helpers/href-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var Helper = Ember.Helper;
  var getOwner = Ember.getOwner;


  function hrefTo(context, targetRouteName) {
    var router = getOwner(context).lookup('service:router');

    if (router === undefined) {
      return;
    }

    for (var _len = arguments.length, rest = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    var lastParam = rest[rest.length - 1];

    var queryParams = {};
    if (lastParam && lastParam.isQueryParams) {
      queryParams = rest.pop();
    }

    var args = [targetRouteName];
    args.push.apply(args, rest);
    args.push({ queryParams: queryParams.values });

    return router.urlFor.apply(router, args);
  }

  exports.hrefTo = hrefTo;
  exports.default = Helper.extend({
    compute: function compute(_ref, namedArgs) {
      var _ref2 = _toArray(_ref),
          targetRouteName = _ref2[0],
          rest = _ref2.slice(1);

      if (namedArgs.params) {
        return hrefTo.apply(undefined, [this].concat(_toConsumableArray(namedArgs.params)));
      } else {
        return hrefTo.apply(undefined, [this, targetRouteName].concat(_toConsumableArray(rest)));
      }
    }
  });
});