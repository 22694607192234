define('paper-password/components/paper-password', ['exports', 'ember-paper/components/paper-input', 'ember-paper/templates/components/paper-input'], function (exports, _paperInput, _paperInput2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var $ = Ember.$;
	exports.default = _paperInput.default.extend({
		layout: _paperInput2.default,

		reveal: true,

		type: 'password',

		didInsertElement: function didInsertElement() {
			if (!this.get('reveal')) {
				return;
			}

			if (this.isDestroyed) {
				return;
			}

			this._super.apply(this, arguments);

			this.attachIcon();
		},
		willDestroyElement: function willDestroyElement() {
			if (!this.get('reveal')) {
				return;
			}

			if (this.isDestroyed) {
				return;
			}

			this._super.apply(this, arguments);

			this.unbindIcon();
		},
		bindEvent: function bindEvent() {
			var namespace = '.passwordReveal';
			var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
			var bindEvent = (supportsTouch ? 'touchstart' : 'click') + namespace;

			return bindEvent;
		},
		unbindIcon: function unbindIcon() {
			if (this.isDestroyed) {
				return;
			}

			var bindEvent = this.bindEvent();

			this.$('input').next('i').unbind(bindEvent);
		},
		attachIcon: function attachIcon() {
			if (this.isDestroyed) {
				return;
			}

			var namespace = '.passwordReveal';
			var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
			var bindEvent = (supportsTouch ? 'touchstart' : 'click') + namespace;

			var open = 'fa-eye';
			var closed = 'fa-eye-slash';
			var classes = 'fa password-reveal';

			var _class = function _class(_classes) {
				return [classes, _classes].join(' ');
			};

			var _style = function _style() {
				return {
					zIndex: '1',
					right: '2px',
					top: '0.4em',
					marginRight: '0',
					fontSize: '1.4em',
					cursor: 'pointer',
					position: 'absolute'
				};
			};

			var toggleEye = function toggleEye($element, $icon) {
				var type = $element.attr('type');
				// let types = ['password', 'text']
				var defaultType = 'password';
				var newType = 'text';

				$element.attr('type', type === defaultType ? newType : defaultType);

				return type === defaultType ? $icon.addClass(open).removeClass(closed) : $icon.addClass(closed).removeClass(open);
			};

			var $this = this.$('input');

			var $icon = $('<i/>', { 'class': _class(closed) }).css(_style());

			$icon.bind(bindEvent, function (e) {
				e.stopImmediatePropagation();
				toggleEye($this, $icon);
				return false;
			});

			$icon.insertAfter($this);
		}
	});
});